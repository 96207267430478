import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const NavContainer = styled(Container)`
  .category {
    text-transform: capitalize;
  }
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 0;
  margin-top: 0.4rem;
  border-radius: 0.3rem;
  .dropdown-menu.show {
    background-color: #eee;
  }
  a.nav-link, a.nav-link:visited {
    color: #444 !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem !important;
    border-right: 1px solid #ccc;
  }
  a.nav-link:hover, .dropdown-menu.show a:hover {
    color: #444;
    background-color: #333;
    color: #ccc !important;
  }
  a:visited {
    background-color: #eee;
    color: #444;
  }
  a > svg {
    margin-right: 0.25rem;
    color: #5ae;
  }

  .toggleBotton {
    color: #333;
    position: absolute;
    right: 1.5rem;
    top: -3.5rem;
    background-color: #ccc;
  }

  @media (max-width: 992px) {
    border: none;
    border-top: 1px solid #ccc;
  }
`;



export {
  NavContainer
};
