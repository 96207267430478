import React from 'react';

import Widget from '../main';
import { LeftList, BottomList, BottomWidget } from './styled';

const LeftLinkWidget = ({
  title, links, readmore
}) => {

  return (
    <Widget title={title}>
      <LeftList>
        {links.map( link => (
          <li key={link.id}><a href={link.url}>{link.text}</a></li>
        ))}
        { readmore && readmore.url && 
            <li className="readmore"><a href={readmore.url}>{readmore.text} »</a></li>
        } 
      </LeftList>
    </Widget>
  );
};

const BottomLinkWidget = ({
  title, links, readmore
}) => {

  return (
    <BottomWidget>
      <h4 className="title">{title}</h4>
      <BottomList>
        {links.map( link => (
          <li key={link.id}><a href={link.url}>{link.text}</a></li>
        ))}
        { readmore && readmore.url && 
            <li className="readmore"><a href={readmore.url}>{readmore.text} »</a></li>
        } 
      </BottomList>
    </BottomWidget>
  );
};

const BottomTextWidget = ({ title, children }) => {
  return (
    <BottomWidget>
      <h4 className="title">{title}</h4>
      {children}
    </BottomWidget>
  );
};


export { 
  LeftLinkWidget,
  BottomLinkWidget,
  BottomTextWidget
};
