import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SSRProvider } from 'react-bootstrap';
import { LeftLinkWidget } from '../../components/widgets/links';
import TwitterWidget from '../../components/widgets/twitter';
import FooterContainer from '../footer';

import { 
  MainContainer,
  BodyContainer,
  MainBody,
  RightBody
} from './styled';

import Header from './../header';

const LatestArticleWidget = () => {
  const data = useStaticQuery(graphql`
  query Latest10Articles {
    allMdx(
      limit: 10,
      sort: {order: DESC, fields: frontmatter___date},
      filter: {fileAbsolutePath: {regex: "/(/content/books|/content/posts)/"}},
    ) {
      nodes {
        frontmatter {
          title
        }
        slug
        id
      }
    }
  }
    `);
  const links = data.allMdx.nodes.map(article => ({
    url: `/${article.slug}`,
    text: article.frontmatter.title,
    id: article.id,
  }));
  return (
    <>
      <LeftLinkWidget title='Latest articles' links={links} readmore='#' />
      <TwitterWidget />
    </>
  );
};

const Layout = ({ children }) => {
  return (
    <SSRProvider>
      <MainContainer>
        <Header/>
        <BodyContainer>
          <MainBody md={9} lg={8}>
            {children}
          </MainBody>
          <RightBody md={3} lg={4}>
            <LatestArticleWidget />            
          </RightBody>
        </BodyContainer>
        <FooterContainer />
      </MainContainer>
    </SSRProvider>
  );
};

export default Layout;
