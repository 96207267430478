import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Text, Box } from './styled';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

const Motto = () => {
  return (
    <Box>
      <Row>
      <Col md={4}>
        <StaticImage alt="Chanon raise his hand" src='./fate.jpg' width={100} height={100} layout='constrained' />
      </Col>
      <Col>
        <Text>
          Work like you don't need the money<br/>
          Love like you'll never be hurt<br/>
          Sing like nobody's listening<br/>
          Dance like nobody's watching<br/>
          <Link to="/about/">More about me »</Link>
        </Text>
      </Col>
      </Row>
    </Box>
  );

};

export default Motto;


