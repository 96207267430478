import React from 'react';
import { Container, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Youtube from '../../components/widgets/youtube';

import { BottomLinkWidget, BottomTextWidget } from '../../components/widgets/links';
import { Copyright, Bottom } from './styled';


const Footer = () => {
  const curYear = () => {
    const curDate = new Date();
    return curDate.getFullYear();
  }
  const data = useStaticQuery(graphql`
  query Latest5Articles {
    post: allMdx(
      limit: 5,
      sort: {order: DESC, fields: frontmatter___date},
      filter: {fileAbsolutePath: {regex: "/\/posts\//"}},
    ) {
      nodes {
        frontmatter {
          title
        }
        slug
        id
      }
    }
    book: allMdx(
      limit: 5,
      sort: {order: DESC, fields: frontmatter___date},
      filter: {fileAbsolutePath: {regex: "/\/books\//"}},
    ) {
      nodes {
        frontmatter {
          title
        }
        slug
        id
      }
    }

  }
    `);
  const postLinks = data.post.nodes.map(article => ({
    url: `/${article.slug}`,
    text: article.frontmatter.title,
    id: article.id,
  }));
  const bookLinks = data.book.nodes.map(article => ({
    url: `/${article.slug}`,
    text: article.frontmatter.title,
    id: article.id,
  }));

  return (
    <Container>
      <Bottom>
        <Col md={4} xm={12}>
          <BottomLinkWidget title='Latest Articles' links={postLinks} />
        </Col>
        <Col md={4} xm={12}>
          <BottomLinkWidget title='Latest Book Reviews' links={bookLinks} />
        </Col>
        <Col md={4} xm={12}>
          <BottomTextWidget title="Featured video">
            <Youtube videoId="hqtMub0P3n0" />
          </BottomTextWidget>
        </Col>
      </Bottom>
      <Copyright>
        <p>Copyright 2019-{curYear()} &copy; all right reserved</p>
        <p>Chanon Srithongsoon's personal blog</p>
        <p>Powered by GatsbyJs</p>
      </Copyright>
    </Container>
  );
};

export default Footer;
