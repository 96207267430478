import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const WidgetLink = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 0.95rem;

  li {
    display: block;
  }

  a {
    text-decoration: none;
    display: block;
  }
`;

const LeftList = styled(WidgetLink)`
  a {
    color: #555;
    padding: 0.5rem 0.75rem;
  }

  a:hover {
    background-color: #eee;
  }

  li {
    border-bottom: 1px dashed #ccc;
  }

  li.readmore {
    border-bottom: none;
    a {
      font-weight: bold;
      text-align: right;
    }
    a:hover {
      background-color: #fff;
      text-decoration: underline;
    }
  }

`;


const BottomList = styled(WidgetLink)`
  a {
    color: #ccc;
    :hover {
      text-decoration: underline;
    }
  }
  
`;

const BottomWidget = styled(Container)`
  h4.title {
    font-size: 0.95rem;
  }
  a {
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
  line-height: 1.2rem;
  li {
    margin-bottom: 0.8rem;
  }
`;

export {
  LeftList,
  BottomList,
  BottomWidget
};
