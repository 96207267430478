import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { MainWidget } from './styled';

const Widget = ({
  children,
  title
}) => {

  return (
    <MainWidget>
      <Row>
        <Col lg={12}>
          <h4 className="title">{ title }</h4> 
        </Col>
        <Col lg={12}>
         { children }
        </Col>
      </Row>
    </MainWidget>
  );
};

Widget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string
};

Widget.defaultProps = {
  title: '',
  children: null
};

export default Widget;
