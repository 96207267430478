import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const MainContainer = styled(Container)`
  padding: 1.7rem 2.5rem;
  @media screen and (max-width: 768px) {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }
  background-color: white;
  border: 1px solid #b8b8b8;
`;

const BodyContainer = styled(Row)`
  padding-top: 0rem;
`;

const MainBody = styled(Col)`
  padding-top:1.25rem;
  padding-left: 1.25rem;
`;

const RightBody = styled(Col)`
  padding-top:1.5rem;
  border-left: 1px solid #ccc;
  padding-right: 1.5rem;
`;

export {
  MainContainer,
  BodyContainer,
  MainBody,
  RightBody
};
