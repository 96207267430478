import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const Text = styled.p`
 font-style: italic; 
 padding: 0;
 color: #ccc;
 font-size: 0.75rem;
 a {
  color: #ccc;
 }
`;

const Box = styled(Container)`
  padding-right: 0;
  border-left: 1px solid #666;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }

`;

export {
  Text,
  Box
}
