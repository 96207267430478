import React from 'react';
import { Link } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import {  Banner, StyledHeader } from './styled';
import MainNav from '../../components/mainNav';
import Motto from '../../components/motto';

const Header = () => {
  return (
        <>
        <StyledHeader>
          <Row>
            <Col lg={8} xl={7}>
              <Banner href="#home"><Link to="/">Chanon Srithongsook's Blog</Link></Banner>
            </Col>
            <Col>
              <Motto />
            </Col>
          </Row>
        </StyledHeader>
        <MainNav/>
        </>
  );
};

export default Header;
