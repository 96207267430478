import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getKebabLink } from '../../utils/kebabCase';
import { NavContainer } from './styled';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { FaHome, FaBook, FaAddressCard } from 'react-icons/fa';

const MainNav = () => {
  const query = graphql`
    query allCategories {
        categories: allMdx(limit: 1000) {
          group(field: frontmatter___category) {
            fieldValue
          }
        }
    }
  `;
  const data = useStaticQuery(query);
  const { group } = data.categories;
  return (
    <Navbar expand="lg">
      <NavContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggleBotton"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Item>
              <Nav.Link href="/">
                <FaHome />
                 Home
              </Nav.Link>
            </Nav.Item>
            <NavDropdown title="Articles">
              {group.map((category) => {
                return (
                  <NavDropdown.Item className="category" href={getKebabLink(category.fieldValue, '/categories/')}>{category.fieldValue}</NavDropdown.Item>
                );
              })} 
            </NavDropdown>
            <Nav.Item>
              <Nav.Link href="/book-reviews/"><FaBook/>Book Reviews</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/about/"><FaAddressCard/>About</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </NavContainer>
    </Navbar>
  );

}

export default MainNav;
