import { Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledNavBar = styled(Navbar)``;

const Banner = styled.span`
  color: #fff !important;
  font-weight: bolder;
  font-size: 2rem;
  padding: 0rem 1.7rem;
  margin-top: 0.85rem;
  display: inline-block;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#fff, #999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
`;

const StyledHeader = styled(Container)`
  background-color: #333;
  color: grey;
  padding: 1rem 1rem;
`;

export {
  StyledNavBar as Navbar,
  StyledHeader,
  Banner
};
