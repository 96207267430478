import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const MainWidget = styled(Container)`
  padding: 0 !important;
  h4.title {
    background-color: #333;
    color: #eee;
    padding: 0.3rem 0.75rem;
    font-size: 0.9rem;
    margin-bottom: 0;
  }


`;


export {
  MainWidget
};
