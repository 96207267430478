import { Row } from 'react-bootstrap';
import styled from 'styled-components';

const Copyright = styled(Row)`
  padding: 0.75rem;
  p {
    text-align: right;
    font-size: 0.75rem;
    color: #888;
    margin-bottom: 0 !important;
    padding: 0;
  }
`;

const Bottom = styled(Row)`
  padding: 1.65rem 0;
  background-color: #333;
  color: #fff;
`;

export {
  Copyright,
  Bottom
};
