import React from 'react';
import { Row } from 'react-bootstrap';
import MainWidget from './../main';

const TwitterWidget = () => {
  return (
    <MainWidget title="My twitter feed">
      <Row>
        <a className="twitter-timeline" 
          href="https://twitter.com/iam_chanon?ref_src=twsrc%5Etfw"
          data-tweet-limit={5}
          >
          Tweets by iam_chanon
        </a>
      </Row>
    </MainWidget>
  );
};

export default TwitterWidget;
